<template>
  <section class="content">
    <div class="row" v-if="contract">
      <div class="col-lg-12">
        <div :class="['box box-solid', { 'bg-transparent': isCustomTab }]">
          <div class="nav-tabs-custom">
            <template v-if="defaultTab">
            <ul
              class="nav nav-tabs ui-sortable-handle"
              :class="$utils.isMobile() ? 'mobile-tab' : 'desktop-tab'"
            >
              <li
                v-for="(item, index) in tabs"
                v-bind:key="index"
                v-bind:title="$tc(item.title, 2)"
                v-bind:class="{ active: item.href == $route.path }"
              >
                <router-link v-bind:to="item.href">
                  <Icon
                    :title="null"
                    v-bind:name="
                      (!item.iconClass.includes('fa ') &&
                      item.iconClass.includes('fa-')
                        ? 'fa '
                        : '') + item.iconClass
                    "
                  />
                </router-link>
              </li>
              <PanelHeaderEquipmentList
                v-if="!$utils.isMobile()"
                v-bind:title="title"
                v-bind:nItems="nItems"
              >
              </PanelHeaderEquipmentList>
            </ul>
            <div class="tab-content no-padding">
              <div
                class="tab-pane active no-padding"
                style="position: relative"
              >
                <router-view
                  v-if="
                    hasPermission((currentTab || {}).rule) ||
                    (currentTab && !currentTab.rule)
                  "
                  :filterCriteria="filterCriteria"
                  :display="display"
                  @nItemsChanged="onItemsChanged"
                  @finished="onFinished($event)"
                  @fetchEquipmentList="fetchEquipmentList()"
                />
                <InfoBox v-else-if="currentTab" preset="unauthorized" />
              </div>
              <div class="container text-center big-box" v-if="isEmpty">
                <div class="box box-solid no-padding">
                  <div
                    class="alert alert-default"
                    style="background-color: whitesmoke"
                  >
                    <div class="h3 text-center">
                      {{ $t("no_equipment_was_found") }}
                    </div>
                    <div
                      class="btn-action"
                      v-if="
                        $can('manage', 'EquipamentoCadastro') &&
                        (!nItems || !nItems.available)
                      "
                    >
                      <router-link to="/dashboard/edit/connector/0#edit">
                        <div class="btn btn-primary btn-lg">
                          {{
                            $t("titles.add_your_first", {
                              item: $tc("connector", 1)
                            })
                          }}
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </template>
            <template v-else>
              <InfoBox
                preset="unauthorized"
                :dismissible="true"
                @close="$store.dispatch('user/logout')"
              />
            </template>
          </div>
          <div class="overlay" v-if="isLoading">
            <i class="fa fa-refresh fa-spin"></i>
          </div>
        </div>
      </div>
    </div>
    <CustomActionManager v-bind:connector="null" @openScreen="openScreen" />
  </section>
</template>

<script>
// @ is an alias to /src
import InfoBox from "@/components/info-box.vue";
import PanelHeaderEquipmentList from "@/components/panel-header-equipment-list.vue";
import CustomActionManager from "@/components/custom-action-manager.vue";
import Icon from "@/components/icons/icon.vue";

export default {
  name: "DashboardSearch",
  components: {
    PanelHeaderEquipmentList,
    InfoBox,
    CustomActionManager,
    Icon
  },
  data: function () {
    return {
      nItems: null, // filtered n itens
      nResult: undefined, // total n itens
      filterCriteria: null
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    title() {
      let self = this;
      let lst = self.tabs.filter(function (i) {
        return i.href == self.$route.path;
      });
      return (
        (lst.length > 0 && lst[0].title) ||
        self.$root.config.equipment_selection.title ||
        self.$t("equipment_list")
      );
    },
    display() {
      return this.tabs.find((i) => i.href == this.$route.path);
    },
    tabs() {
      let self = this;
      let lst = (
        this.$root.config.equipment_selection.tabs.items || [
          {
            name: "station-report",
            href: "/dashboard/search/table",
            title: "device",
            iconClass: "fa-table",
            component: "EquipmentSearchTable",
            enabled: true
          }
        ]
      )
        .filter((i) => {
          let ok = this.contract && i.enabled;
          // user access/contract access rule validation - if required
          if (ok && "rule" in i && i.rule) {
            ok = self.hasPermission(i.rule);
          }
          // custom panel validation - if required
          if (ok && "requireCustomPanel" in i && i.requireCustomPanel) {
            ok = (this?.contract?.portal_data?.search_page || {})[i.component]
              ? true
              : false;
          }
          return ok; // it is enabled and rule was not found or not defined;
        })
        .concat(
          (this.userPortalData.userTabs ?? []).filter((t) =>
            t.contractId ? t.contractId == this.contract?.id : true
          )
        )
        .concat(this.contractPortalData?.userTabs ?? []);
      return this.$utils.isMobile() ? lst.reverse() : lst;
    },
    currentTab() {
      return this.tabs.find(({ href }) => href == this.$route.path);
    },
    userPortalData() {
      return this.$store.getters["user/loggedUser"].user_profile?.portal_data;
    },
    contractPortalData() {
      return this.$store.getters["user/contract"]?.portal_data;
    },
    defaultTab() {
      let tab = this.tabs.find(
        (t) =>
          t.name == this.userPortalData?.defaultDashboardTab ||
          t.name == this.contractPortalData?.defaultDashboardTab
      );
      if (!tab) {
        tab = this.tabs.find(
          (t) => t.name == this.$root.config.equipment_selection.tabs.initial
        );
      }
      return tab;
    },
    isCustomTab() {
      return this.$route.path.includes("custom");
    },
    isFinished() {
      return !this.isLoading && this.nResult !== undefined;
    },
    isEmpty() {
      return this.isFinished && this.nResult === 0;
    }
  },
  watch: {
    "currentTab.href": {
      immediate: true,
      handler: "checkTabExistence"
    },
    contract(n, o) {
      if (!o && n && !this?.tabs?.length) {
        this.$store.dispatch("user/logout");
        return;
      }
      if (n) this.checkTabExistence();
    },
    isLoading(n, o) {
      if (!n && o) {
        this.$root.$emit("refreshDone");
        // console.log("refreshDone");
      }
    }
  },
  methods: {
    onItemsChanged(nItems) {
      this.$set(this, "nItems", nItems);
      if (this.isFinished || this.nResult === undefined) {
        this.$set(this, "nResult", nItems.filtered);
      }
    },
    hasPermission(rules) {
      let lst = (rules || "").split(",");
      for (var i in lst) {
        let rule = lst[i];
        let contract = this.contract ?? {};
        if ((rule in contract && contract[rule]) || this.$can("manage", rule)) {
          continue;
        } else {
          return false;
        }
      }
      return true;
    },
    fetchEquipmentList() {
      // this.$store
      //   .dispatch("fetchEquipmentList")
      //   .then(() => this.$root.$emit("refreshDone"));
      //this.$root.$emit("refreshDone");
      if (this.$store.getters["dashboard/connectorList"].length) {
        this.$root.$emit("refreshDone");
      }
    },
    checkTabExistence() {
      if (!this.currentTab && this.contract && this?.defaultTab?.href)
        this.$router.replace(this.defaultTab.href);
    },
    openScreen({ screenId, connectorId, actionParams }) {
      let url = `/dashboard/equipment/${connectorId || "screen"}/${screenId}`;
      if (url != this.$route.path) {
        if (Object.keys(actionParams || {}).length) {
          localStorage.setItem("_cdim", JSON.stringify(actionParams));
          url = this.$utils.buildUrlSafe(url, { _cdim: "" });
        }
        this.$router.push(url);
      }
    },
    refresh() {
      this.$store.dispatch("dashboard/fetchResourcesState").then(() => {
        this.$root.$emit("refreshDone");
      });
    },
    onFinished(value) {
      this.$set(this, "nResult", value);
    }
  },
  mounted: function () {
    this.$store.dispatch("resetEquipmentData");
    this.$emit("equipmentChanged");
    this.$root.$on("refreshPage", this.refresh);
  },
  beforeDestroy() {
    this.$root.$off("refreshPage", this.refresh);
  }
};
</script>
<style scoped>
.box .overlay,
.overlay-wrapper .overlay {
  background: #ffffff59;
}

.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  opacity: 0.5;
}

.nav-tabs-custom > ul.desktop-tab > li {
  float: right;
}

.nav-tabs-custom > ul.mobile-tab {
  overflow: auto hidden;
  white-space: nowrap;
  max-width: 100%;
}
.nav-tabs-custom > ul.mobile-tab > li {
  display: inline-block;
  float: none;
}

.bg-transparent,
.bg-transparent > .nav-tabs-custom,
.bg-transparent > .nav-tabs-custom > .tab-content {
  background: transparent;
  box-shadow: none;
}

.nav-tabs li {
  height: 3.215em;
}

.nav-tabs li a {
  display: flex;
  align-items: center;
  height: 100%;
}

.tab-content {
  min-height: calc(100vh - 180px);
}

.big-box {
  padding: 2% 2% 8% 2%;
}

.big-box .btn-action {
  padding: 20px 0 40px 0;
}

@media (min-width: 768px) {
  .tab-content {
    min-height: calc(100vh - 130px);
  }
}
</style>
